<template>
  <div class="bg">
    <div class="content">
      <p class="streamer_title">{{ $t("streamer.p2") }}</p>
      <p class="welcome">{{ $t("streamer.p3") }}</p>
      <p class="h2">{{ $t("streamer.p4") }}</p>
      <p>{{ $t("streamer.p5") }}</p>
      <p>{{ $t("streamer.p6") }}</p>
      <p>
        {{ $t("streamer.p16") }}
        <a href="https://d3qgi0t347dz44.cloudfront.net/release/windowspack/riichi_city_stream_2024.zip"
          ><img class="download" src="/static/img/live/download.png" alt=""
        /></a>
      </p>
      <p>
        {{ $t("streamer.p20") }}
        <a href="https://d3qgi0t347dz44.cloudfront.net/release/windowspack/2.5Anniv.zip"
          ><img class="download" src="/static/img/live/download.png" alt=""
        /></a>
      </p>
      <p class="h2">{{ $t("streamer.p8") }}</p>
      <p>{{ $t("streamer.p9") }}</p>
      <p><span class="num">①</span>{{ $t("streamer.p10") }}</p>
      <p><span class="num">②</span>{{ $t("streamer.p11") }}</p>
      <p><span class="num">③</span>{{ $t("streamer.p12") }}</p>
      <p>
        {{ $t("streamer.p13") }}
        <span id="email" class="email">{{ $t("streamer.p15") }}</span>
        <textarea class="input" id="input"></textarea>
        <img
          @click="copyText()"
          class="copy"
          src="/static/img/live/copy.png"
          alt=""
        />
      </p>
      <p class="thanks">{{ $t("streamer.p14") }}</p>
    </div>
    <img
      class="hidden-sm-and-down character"
      src="/static/img/live/character.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    copyText() {
      var text = document.getElementById("email").innerText;
      var input = document.getElementById("input");
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      // document.getElementById("input").removeChild("input"); // 最后删除实例中临时创建的input输入框，完成复制操作
      alert("Copy Successed");
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-image: url("/static/img/live/bg.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  padding: 140px 40px;
  z-index: 1;
  max-width: 1200px;
  p {
    font-size: 18px;
    color: #7d8dfd;
    margin-bottom: 5px;
  }
  .streamer_title {
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
  }
  .welcome {
    margin: 40px 0;
  }
  .h2 {
    font-size: 20px;
    color: #c3cbff;
    margin-top: 40px;
  }
  .download {
    vertical-align: sub;
  }
  .num {
    color: #fccd4e;
    margin-right: 10px;
  }
  .email {
    color: #7dcd80;
  }
  .thanks {
    margin-top: 40px;
  }
  .copy {
    vertical-align: sub;
    cursor: pointer;
    margin-left: 10px;
  }
  .input {
    // display: none;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -10;
  }
}
.character {
  height: 64%;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
@media screen and (max-width: 600px) {
  .bg {
    height: 100%;
  }
  .content {
    padding: 44px 40px;
  }
}
</style>
